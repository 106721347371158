// import { isEmpty } from 'lodash';
import Utils from ".";
import ReactGA from 'react-ga4';

export function getOnDemandProductText(signedInPayment, selectedBundleType, purchaseProductsObj, 
	pageContent, multiStateArray, stateObject, selectedBundleStateObj, includeSelectedLiveEvents, selectedBundle, 
	productNumber, isMultiState, purchaseProductObj) {
		
		const isProduct1 = !productNumber || productNumber === 1;
		selectedBundleType = isProduct1 ? selectedBundleType : selectedBundle?.bundle;
		
		let purchasedCredits = isProduct1 ? purchaseProductsObj?.ProductItems?.[0]?.PurchasedCredits : purchaseProductsObj?.ProductItems?.[1]?.PurchasedCredits;
		if (!purchasedCredits) purchasedCredits = purchaseProductObj?.PurchasedCredits;
		const purchasedCreditsOnDemandText = `${purchasedCredits ? purchasedCredits + ' ' : ''}On Demand Hours`;

		const 	purchasedLiveCredits = isProduct1 ? purchaseProductsObj?.ProductItems?.[0]?.PurchasedLiveCredits : purchaseProductsObj?.ProductItems?.[1]?.PurchasedLiveCredits;

		let onDemandProductText = '';

		const stateAbbr = stateObject?.Abbreviation;
		const cleMcle = stateObject?.IsMCLE ? 'MCLE' : 'CLE';
		const stateAbbrOrMulti = `${isMultiState ? 'Multi-State' : stateAbbr}`;

		switch(selectedBundleType) {
			case 'bundle':
				onDemandProductText = 
					`<b>${stateAbbrOrMulti} State ${cleMcle} Bundle</b><br/>${purchasedCreditsOnDemandText}`;
				break;
			case 'custom':
				onDemandProductText =
					`<b>${stateAbbrOrMulti} Custom ${cleMcle} Package</b><br/>${purchasedCreditsOnDemandText}`;
				break;
			case 'custom-live':
				onDemandProductText = 
				`<b>${stateAbbrOrMulti} Live ${cleMcle} Package</b><br/>` +
				`${purchasedLiveCredits} Live Hours`;
				break;
			case 'a-la-carte':
				onDemandProductText = getAlaCarteProductText(selectedBundle, stateObject?.Abbreviation, cleMcle, isMultiState);
				break;
			case 'unlimited':
				onDemandProductText = `<b>${stateAbbrOrMulti} Unlimited ${cleMcle}</b><br/>Unlimited On Demand Hours`;
				break;
			case 'unlimited-live':
				onDemandProductText = `<b>${stateAbbrOrMulti} Unlimited ${cleMcle}</b><br/>Unlimited On Demand and Live Hours`;
				break;
			case 'all-inclusive':
				onDemandProductText = 
				`<b>${stateAbbrOrMulti} All-Inclusive ${cleMcle} Package</b><br/>` +
				`${purchasedCredits} On Demand Hours and ${purchasedLiveCredits} Live Hours`;
				break;
			case 'bundleupgrade':
				onDemandProductText = `<b>Bundle Upgrade</b><br/>${purchasedCreditsOnDemandText}`;
				break;
			case 'course':
				onDemandProductText = `<b>${stateAbbrOrMulti} A La Carte ${cleMcle} Hours</b><br/>${purchasedCreditsOnDemandText}`;
					break;	
			default:
				break;
		}			

		return onDemandProductText;
}

const getAlaCarteProductText = (selectedBundle, stateAbbr, cleMcle, isMultiState) => {
	
	const onDemandHours = parseFloat(selectedBundle?.hours) ? parseFloat(selectedBundle?.hours) : 0;
	const liveHours = parseFloat(selectedBundle?.hoursLive) ? parseFloat(selectedBundle?.hoursLive) : 0;

	let productText = '';

	if (onDemandHours > 0 && liveHours > 0) { // mixed ondemand and live
		productText = `<b>${isMultiState ? 'Multi-State' : stateAbbr} On Demand and Live Credits</b><br/>` + 
			`${onDemandHours} On Demand ${Utils.pluralize('Hour', onDemandHours)} and ${liveHours} Live ${Utils.pluralize('Hour', liveHours)}`;
	} else if (onDemandHours > 0 && liveHours === 0) { // only ondemand
		productText = `<b>${isMultiState ? 'Multi-State' : stateAbbr} A La Carte ${cleMcle} Hours</b><br/>` +
			`${onDemandHours} On Demand ${Utils.pluralize('Hour', onDemandHours)}`;
	} else if (liveHours > 0 && onDemandHours === 0) { // only live
		productText = `<b>${isMultiState ? 'Multi-State' : stateAbbr} Live ${cleMcle} Credits</b><br/>` +
			`${liveHours} Live Credit ${Utils.pluralize('Hour', liveHours)}`;
	}

	return productText;
}

export function getPurchaseMappingTypeName(product, hasLiveCourses, selectedBundle2 = null) {

	// TODO: need to add following product combinations to ProductPaymentMappingType:
	// payment_benefit_bundle_alacarte_live
	// payment_benefit_custom_state_od_alacarte_live
	// payment_benefit_unlimited_od_alacarte_live

	let purchaseMappingTypeName = null;
	const productType = product?.ProductType?.toLowerCase();
	const productType2 = selectedBundle2?.bundle?.toLowerCase();

	// console.log(product, hasLiveCourses, selectedBundle2);

	if (selectedBundle2) {
		if (productType === 'customlivecredits') {
			switch (productType2) {
				case 'unlimited':
					purchaseMappingTypeName = 'payment_benefit_custom_state_live_unlimited';					
					break;
				case 'bundle':
					purchaseMappingTypeName = 'payment_benefit_custom_state_live_bundle';
					break;
				case 'custom':
					purchaseMappingTypeName = 'payment_benefit_custom_state_live_custom_od';
					break;				
				case 'a-la-carte':
					purchaseMappingTypeName = 'payment_benefit_custom_state_live_alacarte';
					break;
				default:
					break;
			}
		} else if (productType === 'a-la-cartelivecredits') {
			switch (productType2) {
				case 'bundle':
					// purchaseMappingTypeName = 'payment_benefit_alacarte_live_bundle';
					purchaseMappingTypeName = 'payment_benefit_custom_live_bundle'
					break;
				case 'custom':
					// purchaseMappingTypeName = 'payment_benefit_custom_live_custom_state_od';
					purchaseMappingTypeName = 'payment_benefit_alacarte_live_custom_state_od';
					break;
				case 'unlimited':
					// purchaseMappingTypeName = 'payment_benefit_alacarte_live_unlimited';
					purchaseMappingTypeName = 'payment_benefit_custom_live_unlimited';
					break;
				default:
					break;
			}
		}

		return purchaseMappingTypeName;
	}

	if (hasLiveCourses) {
		if (!product) purchaseMappingTypeName = 'payment_benefit_live_courses';
		else {
			switch (productType) {
				case 'bundle':
					purchaseMappingTypeName = 'payment_benefit_live_courses_bundle';
					break;
				case 'custom':
					purchaseMappingTypeName = 'payment_benefit_live_courses_custom_state';
					break;
				case 'alacarte':
				case 'a-la-carte':
						purchaseMappingTypeName = 'payment_benefit_live_courses_alacarte';
					break;
				case 'unlimited':
					purchaseMappingTypeName = 'payment_benefit_live_courses_unlimited';
					break;
				default:
					purchaseMappingTypeName = 'payment_benefit_live_courses';
					break
			}
		}		
	} else { // only on-demand products
		if (!product) purchaseMappingTypeName = null;
		else {
			switch (productType) {
				case 'bundle':
					purchaseMappingTypeName = 'payment_benefit_bundle';
					break;
				case 'custom':
					purchaseMappingTypeName = 'payment_benefit_custom_state_od';
					break;
				case 'customlivecredits':
					purchaseMappingTypeName = 'payment_benefit_custom_state_live';
					break;
				case 'alacarteodlive':
					purchaseMappingTypeName = 'payment_benefit_alacarte_od_live';
					break;
				case 'alacarte':
				case 'a-la-carte':
					purchaseMappingTypeName = 'payment_benefit_alacarte';
					break;
				case 'alacartelivecredits':
					purchaseMappingTypeName = 'payment_benefit_alacarte_live';
					break;
				case 'unlimited':
					purchaseMappingTypeName = 'payment_benefit_unlimited';
					break;
				case 'unlimitedliveandondemand':
					purchaseMappingTypeName = 'payment_benefit_unlimited_od_live';
					break;
				case 'allinclusive':
					purchaseMappingTypeName = 'payment_benefit_all_inclusive';
					break;
				case 'bundleupgrade':
					purchaseMappingTypeName = 'payment_benefit_bundle_upgrade';
					break;
				case 'course':
					purchaseMappingTypeName = 'payment_benefit_insufficient_credits';
					break;
				default:
					purchaseMappingTypeName = '';
					break
			}
		}
	}
	
	return purchaseMappingTypeName;
}


export function getPriceAndProductType (purchaseProductObj) {
	let price;
	let productType;
	const numberOfProductItems = purchaseProductObj?.ProductItems?.length;

	if (purchaseProductObj?.ProductItems && numberOfProductItems > 0) {
		if (numberOfProductItems > 1) { // multiple products
			price = purchaseProductObj?.ProductItems.reduce((acc, product) => acc + product.Price, 0);
			productType = purchaseProductObj?.ProductItems[0]?.Description !== purchaseProductObj?.ProductItems[1]?.Description ? 
				purchaseProductObj?.ProductItems[0]?.Description + ' and ' + purchaseProductObj?.ProductItems[1]?.Description 
				: purchaseProductObj?.ProductItems[0]?.Description;
		} else {
			price = purchaseProductObj?.ProductItems[0]?.Price;
			productType = purchaseProductObj?.ProductItems[0]?.ProductType;
		}
	} else {
		price = purchaseProductObj?.Price;
		productType = purchaseProductObj?.ProductType;
	}

	return { price, productType };
}

export function sendPurchaseDataToGA (purchaseProductObj) {
	
	const source = "LexVid-Integrated";
	const transactionID = purchaseProductObj?.TransactionID;
	const { price, productType } = getPriceAndProductType(purchaseProductObj);
	const productName = purchaseProductObj?.Description;

	//ga4 send purchase data added 5/1/2023
	const transactionData = {
		items: [
			{
				transaction_id: transactionID,
				affiliation: source,
				item_id: productType,
				item_name: productName,
				price: price,
				quantity: 1
			}
		]
	};

	const dataToSend = {
		ecommerce: {
		transaction_id: transactionID,
		value: price,
		currency: "USD",
		items: transactionData.items.map((item) => ({					
			item_id: item.item_id,
			item_name: item.item_name ?? item.item_id,
			affiliation: item.affiliation,
			price: item.price,
			quantity: item.quantity
		}))
		}
	}

	console.log("sending to data layer: ", dataToSend);

	ReactGA.gtag('event', 'purchase', dataToSend);
}
