import React, { useEffect, useState } from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import './Footer.scss';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
// import CookieConsent from 'react-cookie-consent';
import googleLogo from '../../assets/icons/google-play-wb-logo.png';
import appleLogo from '../../assets/icons/apple-wb-logo.png';
// const rackspaceURL = process.env.REACT_APP_RACKSPACE_URL;
import KayakoChat from '../common/Chat/KayakoChat';
import CookieConsent from '../common/CookieConsent';
import CookieSettings from '../common/CookieSettings/CookieSettings';
import { useDispatch, useSelector } from 'react-redux';
import { getCookieConsent, setCookieConsent, setCookieConsentAgreeAll } from '../../actions/authActions';

const Footer = (props) => {

	const dispatch = useDispatch();
	const location = useLocation();
	const authenticated = useIsAuthenticated();

	const { cookieConsent } = useSelector(state => state.auth);
	// console.log(cookieConsent);

	useEffect(() => {
		dispatch(getCookieConsent());
	}, [dispatch]);

	const getCurrentYear = () => {
		return new Date().getFullYear();
	}

	const cookieConsentExists = cookieConsent?.CookieConsentID > 0 && cookieConsent?.NecessaryConsent === 1;
	const [showCookieConsent, setShowCookieConsent] = useState(cookieConsentExists ? false : true);
	const [showSavedSuccessfully, setShowSavedSuccessfully] = useState(false);
	const [saveDisabled, setSaveDisabled] = useState(false);

	// Add this useEffect to update showCookieConsent when cookieConsentExists changes
	useEffect(() => {
		setShowCookieConsent(!cookieConsentExists);
	}, [cookieConsentExists]);

	const handleManagePreferences = () => {
		setShowCookieSettings(true);
	}

	const showSavedMessage = () => {
		setShowSavedSuccessfully(true);
		setTimeout(() => {
			setShowSavedSuccessfully(false);
		}, 1000);
	};

	const handleSavePreferences = (preferences) => {
		setSaveDisabled(true);

		const data = {
			"NecessaryConsent": preferences.necessary ? 1 : 0,
			"PerformanceConsent": preferences.performance ? 1 : 0,
			"FunctionalConsent": preferences.functional ? 1 : 0,
			"AdvertisingConsent": preferences.advertising ? 1 : 0,
		}

		dispatch(setCookieConsent(data)).then(() => {
			setSaveDisabled(false);
			setShowCookieSettings(false);
			setShowCookieConsent(false);

			showSavedMessage();
		});
	}

	const handleAgreeAll = () => {
		setSaveDisabled(true);

		dispatch(setCookieConsentAgreeAll()).then(() => {
			setSaveDisabled(false);
			setShowCookieSettings(false);
			setShowCookieConsent(false);

			showSavedMessage();
		});
	}

	const [showCookieSettings, setShowCookieSettings] = useState(false);
	const handleClose = () => {
		setShowCookieSettings(false);
	}

	const handleCloseConsent = () => {
		setShowCookieConsent(false);

		const data = {
			"NecessaryConsent": 2,
			"PerformanceConsent": 2,
			"FunctionalConsent": 2,
			"AdvertisingConsent": 2,
		}

		dispatch(setCookieConsent(data)).then(() => {
		});

	}


	return <div className='footer-container'>

		<Container>
			<Navbar variant='' sticky='bottom'>

				<Nav className='text-links-wrap'>
					<ul>
						<li>
							<Link to='/about' role="menuitem" className='footer-link'>About</Link>
						</li>
						<li>
							<a href='https://help.lexvid.com/' role="menuitem" target='_blank' rel='noopener noreferrer' className='footer-link'>FAQs</a>
						</li>
						<li>
							<Link to='/contact' role="menuitem" className='footer-link'>Contact</Link>
						</li>
						<li className="mr-2">
							<a className='footer-link' role="menuitem" href='tel:1-877-327-1226'>Call 1-877-327-1226</a>
						</li>
					</ul>
					<ul>
						<li>
							<Link to='/blog' role="menuitem" className='footer-link'>Blog</Link>
						</li>
						<li>
							<Link to='/terms-of-use' role="menuitem" className='footer-link'>Terms of Use</Link>
						</li>
						<li>
							<Link to='/privacy-policy' role="menuitem" className='footer-link'>Privacy Policy</Link>
						</li>
						<li>
							<Link to='/financial-aid-cle' role="menuitem" className='footer-link'>Financial Aid</Link>
						</li>
					</ul>
					<ul>
						<li>
							<Link to='/law-firm-cle' role="menuitem" className='footer-link'>Law Firm &amp; Group CLE</Link>
						</li>
						<li>
							<Link to={`${authenticated ? '/secure' : ''}/cle-referral-program`} role="menuitem" className='footer-link'>Referral Program</Link>
						</li>
						<li>
							<Link to='/free-continuing-legal-education' role="menuitem" className='footer-link'>Free CLE</Link>
						</li>
						<li className='d-lg-none'>
							<Link to='/cle-speakers' role="menuitem" className='footer-link'>LexVid CLE Speakers</Link>
						</li>
						<li className='d-lg-none'>
							<Link to='/cle-speaker/application' role="menuitem" className='footer-link'>Become A Faculty Member</Link>
						</li>
						<li className='d-lg-none'>
							<Link to='/mobile-cle-app' role="menuitem" className='footer-link'>Mobile App</Link>
						</li>
					</ul>
					<ul className='d-none d-lg-block'>
						<li>
							<Link to='/cle-speakers' role="menuitem" className='footer-link'>LexVid CLE Speakers</Link>
						</li>
						<li>
							<Link to='/cle-speaker/application' role="menuitem" className='footer-link'>Become A Faculty Member</Link>
						</li>
						<li>
							<Link to='/mobile-cle-app' role="menuitem" className='footer-link'>Mobile App</Link>
						</li>
					</ul>
				</Nav>

				<Nav className='social-media-apps-icons'>
					<div>
						<a href='https://twitter.com/lexvid' aria-label='Twitter' target='_blank' rel='noopener noreferrer'>
							<i className="fab fa-twitter fa-3x"></i>
						</a>
						<a href='https://www.facebook.com/lexvidcle' aria-label='Facebook' target='_blank' rel='noopener noreferrer'>
							<i className="fab fa-facebook-f fa-3x"></i>
						</a>
						<a href='https://www.linkedin.com/company/lexvid' aria-label='LinkedIn' target='_blank' rel='noopener noreferrer'>
							<i className="fab fa-linkedin fa-3x"></i>
						</a>
						<a href='https://www.youtube.com/channel/UCKmbK6KRKVb1C2rQqcv7kAw' aria-label='Youtube' target='_blank' rel='noopener noreferrer'>
							<i className="fab fa-youtube fa-3x"></i>
						</a>
					</div>
					<div>
						<a href='https://apps.apple.com/us/app/lexvid/id1439636615' aria-label='Apple App Store' target='_blank' rel='noopener noreferrer'>
							<img src={appleLogo} className='apple-app-logo' alt='apple app' width='160' height='47' />
						</a>
						<a href='https://play.google.com/store/apps/details?id=com.lexvidnative&hl=en_US&gl=US' aria-label='Google Play Store' target='_blank' rel='noopener noreferrer'>
							<img src={googleLogo} className='google-play-logo' alt='google app' width='168' height='47' />
						</a>
					</div>
				</Nav>
			</Navbar>
			<div className='footer-brand'>
				@ {getCurrentYear()} LexVid Services, Inc.<br />
				{/*
        <label className='cb-admin-wrap'>Admin?
            <Form.Check checked={isAdmin} onChange={() => changeAdmin()} ref={isAdminRef}></Form.Check>
        </label>
        */}
			</div>
		</Container>

		{showCookieConsent && !showCookieSettings &&
			<CookieConsent
				saveDisabled={saveDisabled}
				onAgree={() => handleAgreeAll()}
				onClose={() => handleCloseConsent()}
				onManagePreferences={() => handleManagePreferences()} />
		}

		{showCookieSettings &&
			<div className='cookie-settings-overlay'>
				<CookieSettings
					saveDisabled={saveDisabled}
					cookieConsent={cookieConsent}
					onSave={(preferences) => handleSavePreferences(preferences)}
					onAgreeAll={() => handleAgreeAll()}
					onClose={() => handleClose()} />
			</div>
		}

		{showSavedSuccessfully &&
			<div className='saved-successfully-overlay'>
				<div className='saved-successfully-content'>
					<div className='saved-successfully-icon'>
						<i className="fas fa-check-circle fa-5x"></i>
					</div>
					<div className='saved-successfully-title'>Your changes were saved successfully.</div>
				</div>
			</div>
		}

		{/* {!authenticated &&
        <div className='cookie-acceptance-wrap'>
            <CookieConsent disableStyles={true} buttonText='' buttonClasses='fal fa-times-square fa-2x'>

                This site uses cookies to help create a great user experience for you, and to help guide us in our continual improvement. By using LexVid, you consent to our <Link to='/privacy-policy'>Privacy Policy</Link> and use of cookies.
            </CookieConsent>
        </div>
    } */}

		{/* Show kayako chat on every page except for payment/checkout/product */}
		{(!location.pathname.includes('payment') && !location.pathname.includes('check-out') && !location.pathname.includes('packages')) ?
			<KayakoChat autoOpen={location.pathname.includes('contact')} /> : null
		}
	</div>
}

export default Footer;