import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import store from './store';

import './assets/styles/index.scss';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from "history";
import initializeSentry from './utils/sentry';


if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	// don't initialize Sentry in development mode
} else {
	// Init Sentry error logging and performance monitoring
	initializeSentry(process.env.REACT_APP_SENTRY_DSN, process.env.NODE_ENV);
}

const history = createBrowserHistory();

history.listen((location, action) => {
	// Scroll to the top of the page on url change forward
	if (action === 'PUSH' || action === 'REPLACE') {
		setTimeout(() => {
			if (!location.hash) window.scrollTo(0, 0);
		}, 100);
	}
})


render(
	<Provider store={store}>
		<Router history={history}>
			<App />
		</Router>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();