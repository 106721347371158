import { useEffect } from "react";
// import ReactGA from 'react-ga4';
import { useSelector } from "react-redux";
import TagManager from "react-gtm-module";


export default function useSendCookieConsentEventsToGA() {

  const cookieConsent = useSelector(state => state.auth.cookieConsent);
  const status = useSelector(state => state.auth.status);
  
  // true = consented to advertising cookies OR have not opted out. False = opted out of advertising cookies)
  const advertisingConsent = !(cookieConsent?.AdvertisingConsent === 0);
  
  // true = consented to performance cookies OR have not opted out. False = opted out of performance cookies)
  const performanceConsent = !(cookieConsent?.PerformanceConsent === 0);

  const locationWithSearch = window.location.pathname + window.location.search;
  const path = window.location.pathname;

  useEffect(() => {
    // if (!locationWithSearch.includes("/loading") && cookieConsent && cookieConsent.CookieConsentID > 0) {

    //   if(advertisingConsent !== 1 && advertisingConsent !== 2) {
    //     console.log("Sending no cookie consent event to GA: 'advertising'");
    //     ReactGA.gtag('event', 'nocookieconsent_advertising');
    //   }

    //   if(performanceConsent !== 1 && performanceConsent !== 2) {
    //     console.log("Sending no cookie consent event to GA: 'performance'");
    //     ReactGA.gtag('event', 'nocookieconsent_performance');
    //   }
    // }

    if (!locationWithSearch.includes("/loading") && status === 'success') {
      const dataLayerData = {
        cookieconsent_advertising : advertisingConsent,
        cookieconsent_performance : performanceConsent
      }

      console.log("Sending cookie consent variables to dataLayer: ", dataLayerData);

      TagManager.dataLayer({
        dataLayer: dataLayerData
      });
    }
  }, [locationWithSearch, path, cookieConsent, advertisingConsent, performanceConsent, status]);
}