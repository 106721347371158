import axios from '../utils/axiosInterceptors';

const baseURL = process.env.REACT_APP_LEXVID_API;

const signup = (email, password, firstName, lastName, isParalegal, bars, referredBy, offlineID) => {
	const url = `${baseURL}/api/Account/Create`;

	const signup = axios.post(url, {
		EmailAddress: email,
		FirstName: firstName,
		LastName: lastName,
		IsParalegal: isParalegal,
		Password: password,
		Bars: bars,
		ReferringMemberID: referredBy,
		// OfflineID: offlineID,
		OfflineMemberID: offlineID
	})
		.then(response => {
			if (response.status === 200)
				return response;
		})
		.catch(error => {
			console.log(error);
		});

	return signup;
}

const login = (email, password) => {
	const url = `${baseURL}/api/Account/Login`;

	const login = axios.post(url, { EmailAddress: email, Password: password })
		.then(response => {
			if (response.status === 200)
				return response;
		})
		.catch(error => {
			console.log(error);
		});

	return login;
}

const getMember = token => {
	const url = `${baseURL}/api/Member/shallow`;

	// need to pass token here, b/c this method can be called while not yet authorized
	const member = axios.get(url, { headers: { Authorization: `Lexvid ${token}` } })
		.then(response => {
			if (response.status === 200)
				return response;
		})
		.catch(error => {
			console.log(error);
		});

	return member;
}

const refreshUser = () => {
	const url = `${baseURL}/api/Member/shallow`;

	const member = axios.get(url)
		.then(response => {
			if (response.status === 200)
				return response.data;
		})
		.catch(error => {
			console.log(error);
		});

	return member;
}

const forgotPassword = email => {
	const url = `${baseURL}/api/Account/forgot-password`;

	const forgotPasswordResult = axios.post(url, { MemberIdentifier: "", EmailAddress: email })
		.then(response => {
			if (response.status === 200)
				return response;
		})
		.catch(error => {
			console.log(error);
		});

	return forgotPasswordResult;
}

const forgotPasswordLogin = (memberId, token) => {
	const url = `${baseURL}/api/account/ForgotpasswordLogin/${memberId}/${token}`;

	const forgotPasswordLoginResult = axios.post(url)
		.then(response => {
			if (response.status === 200)
				return response;
		})
		.catch(error => {
			console.log(error);
		});

	return forgotPasswordLoginResult;
}

const checkout = (checkoutData, cartId) => {
	let URL = '';
	if (cartId) URL = `${baseURL}/api/Account/Create/LiveEvents/${cartId}`
	//else URL = `${baseURL}/api/Account/Create`;
	else URL = `${baseURL}/api/Account/CreateLiveOd`;

	const checkoutResult = axios({
		method: 'POST',
		url: URL,
		data: checkoutData
	}).then((response) => {
		// console.log('CHECKOUT API', response)
		if (response.status === 200)
			return response;
	}).catch((error) => {
		console.log(error);
	});

	return checkoutResult;
}

const setCookieConsent = (consent) => {
	const url = `${baseURL}/api/cookieconsent`;

	const setCookieConsentResult = axios.post(url, consent)
		.then(response => {
			if (response.status === 200)
				return response;
		}).catch(error => {
			console.log(error);
			return error;
		});

	return setCookieConsentResult;
}

const getCookieConsent = async (deviceId, memberId) => {
	const url = `${baseURL}/api/cookieconsent`;

	const getCookieConsentResult = await axios({
		method: 'get',
		url: url,
		params: {
			"DeviceID": deviceId,
			"MemberID": memberId
		},
	}).then(response => {
		if (response.status === 200)
			return response.data;
	});

	return getCookieConsentResult;
}

export default {
	signup,
	login,
	getMember,
	forgotPassword,
	forgotPasswordLogin,
	checkout,
	refreshUser,
	getCookieConsent,
	setCookieConsent
}