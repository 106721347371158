import React from 'react';
import { Container, Button } from 'react-bootstrap';
import './CookieConsent.scss';
import { Link } from 'react-router-dom';

const CookieConsent = (props) => {

  const { saveDisabled, onAgree, onClose, onManagePreferences } = props;

  const agreeToAll = () => {
    onAgree();
  }

  const managePreferences = () => {
    onManagePreferences();
  }

  const closeCookieConsent = () => {
    onClose();
  }

  return <div className='cookie-consent-wrap'>
    <Container>
      <div className='cookie-consent-inner'>
        <div>
          We Value Your Privacy
          <button type="button" className="close" onClick={closeCookieConsent}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
        </div>

        <div>
          This website uses cookies and other tracking technologies to offer you a better experience, personalize content and ads, and analyze our site traffic.
        </div>

        <div className='cookie-consent-buttons'>

          <div>
            <Button variant='primary-blue' onClick={agreeToAll} disabled={saveDisabled}>Agree to All</Button>
            <Button variant='secondary' onClick={managePreferences}>Manage Preferences</Button>
          </div>

          <div>
            <Link to='/privacy-policy' target='_blank'>Privacy Policy</Link>
            <Link to='/terms-of-use' target='_blank'>Terms of Use</Link>
          </div>
        </div>
      </div>
    </Container>
  </div>

}

export default CookieConsent;