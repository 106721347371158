import React, { useState } from 'react';
import './CookieSettings.scss';
import { Button } from 'react-bootstrap';

const CookieSettings = ({ onSave, onAgreeAll, onClose, cookieConsent, saveDisabled, showActionButtons = true, editable = true }) => {
  // console.log(cookieConsent);
  
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true, // Always enabled
    performance: cookieConsent?.PerformanceConsent ?? true,
    functional: cookieConsent?.FunctionalConsent ?? true,
    advertising: cookieConsent?.AdvertisingConsent ?? true
  });

  const handleToggle = (cookieType) => {
    if (cookieType === 'necessary') return; // Necessary cookies can't be disabled
    setCookiePreferences((prev) => ({
      ...prev,
      [cookieType]: !prev[cookieType],
    }));
  };

  const handleSave = () => {
    onSave(cookiePreferences);
  };

  const handleAgreeAll = () => {
    onAgreeAll();
    setCookiePreferences({
      necessary: true,
      performance: true,
      functional: true,
      advertising: true,
    });
  };



  return (
    <div className="cookie-settings-container">
      <div className="cookieSection">
        
        <div className="cookieType">
          <div>
            <h3>Necessary Cookies</h3>
            <p>Essential for the website's functionality, without which the site cannot operate smoothly.</p>
          </div>
          <label className={`toggle disabled ${!editable ? 'disabled-hard' : ''}`}>
            <input type="checkbox" checked readOnly />
            <span className="slider" />
          </label>

        </div>

        <div className="cookieType">
          <div>
            <h3>Performance Cookies</h3>
            <p>Collect data on how users interact with the website, helping to improve user experience.</p>

          </div>
          <label className={`toggle ${!editable ? 'disabled-hard' : ''}`}>
            <input
              type="checkbox"
              checked={cookiePreferences.performance}
              onChange={() => editable && handleToggle('performance')}
            />
            <span className="slider" />
          </label>
        </div>


        <div className="cookieType">
          <div>
            <h3>Functional Cookies</h3>
            <p>Remember user preferences and choices to provide a more personalized experience.</p>
          </div>
          <label className={`toggle ${!editable ? 'disabled-hard' : ''}`}>
            <input
              type="checkbox"
              checked={cookiePreferences.functional}
              onChange={() => editable && handleToggle('functional')}
            />
            <span className="slider" />
          </label>
        </div>

        <div className="cookieType">
          <div>
            <h3>Advertising Cookies</h3>
            <p>Used to deliver targeted advertisements to users based on their browsing behavior and preferences.</p>
          </div>
          <label className={`toggle ${!editable ? 'disabled-hard' : ''}`}>
            <input
              type="checkbox"
              checked={cookiePreferences.advertising}
              onChange={() => editable &&   handleToggle('advertising')}
            />
            <span className="slider" />
          </label>
        </div>
      </div>

      {showActionButtons ? <div className="actions">
        <Button id='save-cookie-settings' variant="primary-blue" onClick={handleSave} disabled={saveDisabled}>
          Save Changes
        </Button>

        <button className="agreeButton" onClick={handleAgreeAll} disabled={saveDisabled}>
          Agree to All
        </button>
      </div> : null}

      <button 
        className="closeButton"
        onClick={onClose}
        aria-label="Close"
      >
        <i className="far fa-chevron-up"></i>
      </button>

    </div>
  );
};

export default CookieSettings;
